import { states } from 'lib/states'
import Link from 'next/link'

export type LocationListProps = {
  state: string
  cities: string[]
}

export default function LocationList({ state, cities }: LocationListProps) {
  return (
    <div className='mt-10 location'>
      <Link href={`/locations/${state}`}>
        <a className='font-bold text-4xl leading-normal hover:text-primary'>{states[state]}</a>
      </Link>
      <div className='flex flex-col p-5 gap-2.5'>
        {cities.map((city) => (
          <Link key={city} href={`/locations/${city}`}>
            <a className='text-lg leading-tight hover:text-primary'>{city}</a>
          </Link>
        ))}
      </div>
      <style jsx>{`
        .location {
          grid-row: 1 / -1;
          grid-column: 1;
        }
      `}</style>
    </div>
  )
}
