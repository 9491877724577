import { PrimaryButton } from 'ui/atoms'
import { basePath } from 'lib/env'
import Link from 'next/link'

export function SiteFooter() {
  return (
    <div className='w-full pt-20 pb-10 text-sm text-gray-300'>
      <img
        className='mx-auto w-15 center rounded-lg'
        src={`${basePath}/static/images/placeholder.png`}
        alt='Logo'
      />
      <p className='text-center my-5'>© 2021 Barstool Sports - All Rights Reserved.</p>
      <div className='w-auto flex justify-center items-center space-x-5'>
        <Link href='https://www.barstoolsports.com/terms-of-use'>
          <a>Terms of Use</a>
        </Link>
        <Link href='https://www.barstoolsports.com/privacy-policy'>
          <a>Privacy Policy</a>
        </Link>
      </div>
    </div>
  )
}
