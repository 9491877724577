import { PrimaryButton } from 'ui/atoms'
import Link from 'next/link'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { authService, useCurrentUser } from 'api/auth'
import { XIcon, MenuIcon } from '@heroicons/react/solid'
import { basePath } from 'lib/env'

interface SiteHeaderProps {
  showMenu: boolean
  setShowMenu: Dispatch<SetStateAction<boolean>>
}

export function SiteHeader({ showMenu, setShowMenu }: SiteHeaderProps) {
  const user = useCurrentUser()

  const navLinks = [
    {
      text: 'Jobs',
      link: '/'
    },
    {
      text: 'Companies',
      link: '/companies'
    },
    {
      text: 'Locations',
      link: '/locations'
    }
  ]

  return (
    <>
      <div className='container w-full flex items-center justify-between p-3 md:px-4 xl:px-0'>
        <Link href='/'>
          <a>
            <img
              className='center w-52 md:w-64'
              src={`${basePath}/static/images/WLAG-logo.png`}
              alt='Logo'
            />
          </a>
        </Link>
        {showMenu ? (
          <XIcon className='w-10 lg:hidden' onClick={() => setShowMenu(false)} />
        ) : (
          <MenuIcon className='w-10 lg:hidden' onClick={() => setShowMenu(true)} />
        )}
        <div className='hidden self-center flex-grow space-x-5 justify-center lg:flex '>
          {navLinks.map(({ text, link }, idx) => (
            <Link key={idx} href={link}>
              <a className='hover:text-primary text-sm xl:text-base'>{text}</a>
            </Link>
          ))}
        </div>
        <div className='hidden w-1/4 items-center justify-end lg:flex xl:w-1/5'>
          <Link href={user?.id ? `/account/my-jobs` : '/account/signin'}>
            <a className='hover:text-primary text-sm xl:text-base'>
              {user ? 'My Jobs' : 'Sign In'}
            </a>
          </Link>
          <Link href='/posts/create'>
            <a className='btn border-none ml-5 py-2.5 w-auto rounded-full text-sm leading-tight'>
              Post a Job
            </a>
          </Link>
        </div>
      </div>
      {showMenu && (
        <div className='flex flex-col items-center space-y-10 p-5 px-4 text-2xl lg:hidden'>
          {navLinks.map(({ text, link }, idx) => (
            <Link key={idx} href={link}>
              <a className='hover:text-primary'>{text}</a>
            </Link>
          ))}
          <Link href={user?.id ? `/account/my-jobs` : '/account/signin'}>
            <a className='hover:text-primary'>{user ? 'My Jobs' : 'Sign In'}</a>
          </Link>
          <Link href='/posts/create'>
            <a className='btn border-none rounded-full text-sm leading-tight'>Post a Job</a>
          </Link>
        </div>
      )}
    </>
  )
}
