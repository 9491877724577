import Head from 'next/head'
import { useState } from 'react'
import { siteName } from 'lib/env'
import { SiteFooter } from './site-footer'
import { SiteHeader } from './site-header'

export type SiteLayoutProps = {
  title?: string | boolean
  children: any
}

export function SiteLayout({ title = false, children }: SiteLayoutProps) {
  const [showMenu, setShowMenu] = useState<boolean>(false)

  return (
    <div className='bg-white'>
      <Head>
        <title>{title ? `${title} | ${siteName}` : siteName}</title>
      </Head>
      <SiteHeader showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className='relative'>
        {showMenu && (
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-80 z-10 md:hidden' />
        )}
        <main className='min-h-screen'>{children}</main>
        <SiteFooter />
      </div>
    </div>
  )
}
