import { useEffect, useState } from 'react'
import { Service } from 'api/common'
import { isServer } from 'lib/utils'
import type { User, UserCredentials } from './types'

export type { User, UserCredentials }

export interface AuthResponse {
  user: User
  credentials: UserCredentials
}

export class AuthService extends Service<UserCredentials> {
  loginEmail?: string

  constructor() {
    super('/auth')
  }

  async checkEmail(email: string): Promise<any> {
    const res: { email: string; exists: boolean } = await this.post('/auth/email', { email })
    this.loginEmail = res.email
    return res
  }

  async signup(name: string, email: string, password: string): Promise<AuthResponse> {
    const res: AuthResponse = await this.post('/auth/signup', { name, email, password })
    this.user = res.user
    this.userCredentials = res.credentials
    return res
  }

  async login(email: string, password: string): Promise<AuthResponse> {
    const res = await this._login(email, password)
    this.user = res.user
    this.userCredentials = res.credentials
    return res
  }

  async forgotPassword(email: string): Promise<void> {
    await this.post('/auth/forgot', { email })
  }

  async resetPassword(token: string, password: string): Promise<AuthResponse> {
    const res: AuthResponse = await this.post('/auth/reset', { token, password })
    this.user = res.user
    this.userCredentials = res.credentials
    return res
  }

  async refreshCredentials(): Promise<AuthResponse> {
    const res: AuthResponse = await this.post('/auth/refresh', {})
    this.user = res.user
    this.userCredentials = res.credentials
    return res
  }

  private async _login(email: string, password: string): Promise<AuthResponse> {
    return this.post('/auth/login', { email, password })
  }

  logout() {
    this.user = null
    this.userCredentials = null
  }
}

export const authService = new AuthService()

export function useCurrentUser(): User | null {
  const [user] = useState<User | null>(() => (isServer ? null : authService.user))
  return user
}

export function useAuthRefresh() {
  useEffect(() => {
    const credentials = authService.userCredentials
    if (!credentials) {
      return
    }
    const diff = new Date(credentials.expires_at).getTime() - Date.now()
    if (diff < 3 * 24 * 60 * 60 * 1000) {
      authService.refreshCredentials()
    }
  }, [])
}
